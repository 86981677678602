import { bindAll } from 'underscore';
import appVersion from '@src-js/utils/app-version-helper';
import getHeapSessionId from '../../../javascript/src/utils/heap-helpers';
import { queryStringFromHash } from './view_helpers';

// App.GoLinks
export default {
  initialize: function () {
    bindAll(this, 'handleGoLinkClick', 'handleDealGoLinkClick', 'handleCouponGoLinkClick', 'handleMerchantGoLinkClick', 'sendGoogleTagManagerEvent');
    this.registerEvents();
  },

  registerEvents: function () {
    $(document).on('click', 'a[href*="/go/p/"]', this.handleDealGoLinkClick);
    $(document).on('click', 'a[href*="/go/c/"]', this.handleCouponGoLinkClick);
    $(document).on('click', 'a[href*="/go/m/"]', this.handleMerchantGoLinkClick);
  },

  handleDealGoLinkClick: function (e) {
    this.sendGoogleTagManagerEvent('deal');
    this.handleGoLinkClick(e, 'deal');
  },

  handleMerchantGoLinkClick: function (e) {
    this.sendGoogleTagManagerEvent('merchant');
    this.handleGoLinkClick(e, 'merchant');
  },

  handleCouponGoLinkClick: function (e) {
    this.sendGoogleTagManagerEvent('coupon');

    const hasCouponModalFlow = $(e.currentTarget).parents('.has-coupon-modal-flow');

    if (hasCouponModalFlow.length > 0) {
      this.handleCouponModalFlowGoLinkClick(e, window);
    } else {
      this.handleGoLinkClick(e, 'coupon');
    }
  },

  handleCouponModalFlowGoLinkClick: function (e, win) {
    e.preventDefault();

    const $goLinkElement = $(e.currentTarget);
    const goLinkHref = $goLinkElement.attr('href');
    const id = this.parseItemId(goLinkHref);
    const goLinkUrl = `${goLinkHref}?${this.eventQueryParams($goLinkElement)}`;

    App.Dispatcher.trigger('goLinkClick', {
      itemType: 'coupon',
      itemId: id,
    });

    let couponModalUrl = win.location.pathname;
    const params = App.Data.currentUrlParameters();
    const couponModalParams = {};

    couponModalParams.m = 'coupon';
    couponModalParams.coupon_id = id;

    const queryParams = Object.assign(params, couponModalParams);

    const queryString = queryStringFromHash(queryParams);

    couponModalUrl += `?${queryString}`;

    this.openCouponFlowLink(couponModalUrl, win);

    this.assignCurrentUrl(goLinkUrl, win);
  },

  assignCurrentUrl: function (url, win) {
    win.location.assign(url);
  },

  openCouponFlowLink: function (url, win) {
    win.open(url);
  },

  handleGoLinkClick: function (event, itemType) {
    event.preventDefault();

    const id = this.parseItemId(event.currentTarget.href);

    App.Dispatcher.trigger('goLinkClick', {
      itemType: itemType,
      itemId: id,
    });

    const $target = $(event.currentTarget);
    const targetUrl = `${$target.attr('href')}?${this.eventQueryParams($target)}`;

    this.openGoLink(targetUrl, window);
  },

  // To address a problem within the facebook in app browser
  // that was preventing a new tab from being opened for our go-links.
  // Replace location if they are on a facebook in app browser.
  // Open window in new tab(default functionality) if they are
  // on any other browser.
  openGoLink: function (targetUrl, win) {
    if (this.isFacebookInAppBrowser(navigator.userAgent)) {
      win.location.replace(targetUrl);
    } else {
      win.open(targetUrl);
    }
  },

  isFacebookInAppBrowser: function (userAgent) {
    return (/FB_IAB/).test(userAgent);
  },

  sendGoogleTagManagerEvent: function (itemType) {
    window.dataLayer = window.dataLayer || undefined;

    if (dataLayer) {
      dataLayer.push({
        event: 'goLinkClick',
        itemType: itemType,
      });
    }
  },

  eventQueryParams: function ($target) {
    const targetModule = $target.parents('[data-module]');
    const targetPosition = $target.parents('[data-position]');

    const params = {
      channel: 'web',
      originating_product: 'web',
      url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
    };

    const heapSessionId = getHeapSessionId();
    if (heapSessionId) {
      params.heap_session_id = heapSessionId;
    }

    const heapUserId = window.heap ? window.heap.userId : false;
    if (heapUserId) {
      params.heap_user_id = heapUserId;
    }

    const version = appVersion.getAppVersion();
    if (version) {
      params.app_version = version;
    }

    const location = $('html').attr('data-loc');
    if (location !== undefined) {
      params.location = location;
    }

    const module = targetModule.data('module');
    if (module !== undefined) {
      params.module = module;
    }

    const position = targetPosition.data('position');
    if (position !== undefined) {
      params.position = position + 1;
    }

    // Get anonymous ID from Segment module
    const anonymousId = window.Segment && window.Segment.anonymousId;
    if (anonymousId !== undefined) {
      params.anonymous_id = anonymousId;
    }

    return $.param(params);
  },

  parseItemId: function (href) {
    href = href || '';
    const matches = href.match(/\/go\/[pcm]\/(\d+)/);
    if (matches && matches.length == 2) {
      return parseInt(matches[1]);
    }
  },
};
