import _ from 'underscore';
import $ from 'jquery';
import Clipboard from 'clipboard';
import CryptoJS from 'crypto-js/core';

/** @TODO FIXME
 * The sst library has circular dependencies. In its current state it is not compatable with ES modules
 */
// import $ from 'jquery';
import sst from '@vendor-js/sst';
import Segment from '@src-js/utils/segment';
import Facebook from '@legacy-js/lib/facebook';
import Forms from '@legacy-js/lib/forms/forms';
import FuzzyTime from '@legacy-js/lib/fuzzy_time';
import GoLinks from '@legacy-js/lib/go_links';
import Google from '@legacy-js/lib/google';
import Scrolling from '@legacy-js/lib/scrolling';
import Twitter from '@legacy-js/lib/twitter';

// View Helper
import * as ViewHelpers from '@legacy-js/lib/view_helpers';

// eslint-disable-next-line no-unused-vars
import BdUser from '../src/app/bd_user';
import Base from '../src/app/base';
import Assets from '../src/app/assets.js.erb';
import Controllers from '../src/app/controllers';
import { Models, Collections } from '../src/app/models';
import Views from '../src/app/views';

window.sst = sst;
window.Segment = new Segment();

/** Setup Peformance Listeners */
require('@src-js/utils/performance-metrics');

/** jQuery Plugins */
require('jquery-validation');
require('jquery-ujs');

/** Exteand Backbone Sync function with Rails headers and more */
require('@vendor-js/backbone_rails_sync');

/** Import specific cryptographic libraries */
require('crypto-js/sha1');
require('crypto-js/md5');
require('crypto-js/sha256');
require('crypto-js/enc-base64');

// Core App with base deps and singletons
window.App = {
  Assets,
  Collections,
  Controllers,
  FB: Facebook,
  GoLinks,
  Google,
  Scrolling,
  Twitter,
  Models,
  Views,
  ViewHelpers,
  ...Base,
};

// Initialize App Singletons
Forms.initialize();
FuzzyTime.initialize(window.App);
GoLinks.initialize();
Facebook.initialize(window.App);
Google.initialize(window.App);
Scrolling.initialize(window.App);
Twitter.initialize(window.App);

window.$(App.initialize);

// Initialize Controllers
Object.values(Controllers).forEach((c) => c.initialize());

// TODO - Change to default include via environment ia Webpack
require('@legacy-js/test_support.js');
